import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect } from "react-redux";
import { APISite } from "../../../server/types/index.js";
import { getActiveSite, getPrimaryDomain } from "../../selectors/sites.js";
import {
  Accommodation,
  BaseModuleProps,
  ColorScheme,
  ImprintModuleSettings,
  Language,
  LegalForm,
  StoreState,
  TranslatedModule,
} from "../../types/index.js";
import { legalForms } from "../../utils/data.js";
import {
  getActiveColorScheme,
  getLinkStyleClass,
  getTranslations,
} from "../../utils/utils.js";
import Copyrights from "../Copyrights.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import MultilineText from "../MultilineText.js";

interface Props extends BaseModuleProps<ImprintModuleSettings> {}

interface StateProps {
  site: APISite;
  accommodation: Partial<Accommodation>;
  scheme: ColorScheme;
  i18n: typeof translations.en;
}

const creatEmailLink = (email?: string) =>
  email ? <a href={`mailto:${email}`}>{email}</a> : null;

interface TranslationContentParams {
  site: APISite;
  translatedModule: TranslatedModule<ImprintModuleSettings>;
  accommodation: Partial<Accommodation>;
  languageId: Language;
  scheme: ColorScheme;
}

type LegalFormTranslations = { [key in LegalForm]: string };

const legalFormTranslationsDE = legalForms.reduce<LegalFormTranslations>(
  (carry, value) => ({ ...carry, [value]: value }),
  {} as LegalFormTranslations
);

const legalFormTranslationsIT: LegalFormTranslations = {
  Einzelunternehmen: "impresa individuale",
  "Einfache Gesellschaft": "società semplice",
  "Offene Handelsgesellschaft": "Società in nome collettivo",
  "Einfache Kommanditgesellschaft": "società in accomandita semplice",
  "Gesellschaft mit beschränkter Haftung": "società a responsabilità limitata",
  "Ein-Personen-Gesellschaft": "s.r.l.-unipersonale",
  Aktiengesellschaft: "società per azioni",
  "Ein-Personen-Aktiengesellschaft": "s.p.a. unipersonale",
  "Kommanditgesellschaft auf Aktien": "Società in accomandita per azioni",
  Genossenschaft: "società coomperativa",
};

const translations = {
  de: {
    title: "Impressum",
    privacy: "Datenschutz",
    content: ({
      accommodation,
      site,
      languageId,
      scheme,
      translatedModule: {
        settings: {
          legalForm,
          paidUpShareCapital,
          pecAddress,
          reaNumber,
          taxCode,
        },
        translation: {
          settings: { legalAddress },
        },
      },
    }: TranslationContentParams) => (
      <>
        <p>
          {accommodation.name}{" "}
          {legalForm && <>({legalFormTranslationsDE[legalForm]})</>}
          <br />
          Gesetzlicher Vertreter: {site.legalRepresentative}
          <br />
          <br />
          {legalAddress ? (
            <>
              Domizil:
              <br />
            </>
          ) : (
            <>
              Rechtssitz und Domizil:
              <br />
            </>
          )}
          {accommodation.street}
          <br />
          {accommodation.zip} {accommodation.city}
          <br />
          Südtirol – Italien
          {legalAddress && (
            <>
              <br />
              <br />
              Rechtssitz:
              <br />
              <MultilineText text={legalAddress} />
            </>
          )}
          <br />
          <br />
          Tel: {accommodation.phone}
          {accommodation.fax && (
            <>
              <br />
              Fax: {accommodation.fax}
            </>
          )}
          <br />
          <br />
          E-Mail: {creatEmailLink(accommodation.email)}
          {!!pecAddress && (
            <>
              <br />
              PEC: {creatEmailLink(pecAddress)}
            </>
          )}
          <br />
          Webseite: {getPrimaryDomain(site)}
        </p>
        <p>
          MwSt.-Nr.: {site.vatId}
          {checkIsEinzelunternehmen(legalForm) && (
            <>
              <br />
              St.-Nr.: {taxCode}
            </>
          )}
          {accommodation?.cin && (
            <>
              <br />
              CIN: {accommodation.cin}
            </>
          )}
          <br />
          <br />
          {!!reaNumber && (
            <>
              Eingetragen im Handelsregister der Provinz Bozen Nr.
              <br />
              REA-Nr.: {reaNumber}
              <br />
            </>
          )}
          {checkIsLegalFormWithPaidUpShareCapital(legalForm) &&
            paidUpShareCapital && (
              <>
                <br />
                Eingezahltes Gesellschaftskapital:{" "}
                {formatMoney(paidUpShareCapital)}
              </>
            )}
          {checkIsCompanyWithSoleShareholder(legalForm) && (
            <>
              <br />
              Gesellschaft mit einem einzigen Gesellschafter
            </>
          )}
        </p>

        <h2>Konzept, Webdesign, Programmierung und CMS</h2>
        <p>
          Hoteliers- und Gastwirteverband (HGV)
          <br />
          Schlachthofstraße 59
          <br />
          39100 Bozen
          <br />
          Südtirol – Italien
          <br />
          Tel. 0471 317 700 – Fax 0471 317 701
          <br />
          E-Mail: <a href="mailto:info@hgv.it">info@hgv.it</a>
          <br />
          Web:{" "}
          <a target="_blank" href="https://www.hgv.it/">
            www.hgv.it
          </a>
        </p>

        <Copyrights siteId={site.id} languageId={languageId} scheme={scheme} />

        <h2>Urheberrecht</h2>
        <p>
          Die auf dieser Website veröffentlichten Texte, Bilder, Fotos und
          anderen Inhalte sind urheberrechtlich geschützt und dürfen ohne
          vorherige Zustimmung weder ganz noch teilweise vervielfältigt,
          weitergegeben, verbreitet oder gespeichert werden.
        </p>
        <p>
          <strong>
            © {accommodation.name} {new Date().getFullYear()} – Alle Rechte
            vorbehalten
          </strong>
        </p>

        <h2>Transparenz</h2>
        <p>
          Veröffentlichung und Transparenz gemäß Art. 1, Abs. 125 ff. Gesetz Nr.
          124 vom 4. August 2017 und nachfolgende Änderungen:{" "}
          <a
            target="_blank"
            href="https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx"
          >
            https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx
          </a>
        </p>

        <h2>Haftungsausschluss</h2>
        <p>
          Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte wird
          jedoch keine Haftung übernehmen. Trotz sorgfältiger inhaltlicher
          Kontrolle wird keine Haftung für die Inhalte externer Links
          übernommen. Für den Inhalt der verlinkten Seiten sind ausschließlich
          deren Betreiber verantwortlich. Bei Kenntnis von Rechtsverstößen
          werden externe Links unverzüglich gelöscht.
        </p>

        <p>
          <strong>
            Pflichtinformation nach EU-Verordnung Nr. 524/2013 des Europäischen
            Parlaments und Rats:
          </strong>
          <br />
          Plattform zur Online-Beilegung verbraucherrechtlicher Streitigkeiten
          (ODR) der Europäischen Kommission:{" "}
          <a target="_blank" href="https://ec.europa.eu/consumers/odr/">
            https://ec.europa.eu/consumers/odr/
          </a>
        </p>
      </>
    ),
  },
  it: {
    title: "Crediti",
    privacy: "Privacy",
    content: ({
      accommodation,
      site,
      languageId,
      scheme,
      translatedModule: {
        settings: {
          legalForm,
          paidUpShareCapital,
          pecAddress,
          reaNumber,
          taxCode,
        },
        translation: {
          settings: { legalAddress },
        },
      },
    }: TranslationContentParams) => (
      <>
        <p>
          {accommodation.name}{" "}
          {legalForm && <>({legalFormTranslationsIT[legalForm]})</>}
          <br />
          Legale rappresentante: {site.legalRepresentative}
          <br />
          <br />
          {legalAddress ? (
            <>
              Domicilio:
              <br />
            </>
          ) : (
            <>
              Domicilio legale e domicilio:
              <br />
            </>
          )}
          {accommodation.street}
          <br />
          {accommodation.zip} {accommodation.city}
          <br />
          Alto Adige – Italia
          {legalAddress && (
            <>
              <br />
              <br />
              Domicilio legale:
              <br />
              <MultilineText text={legalAddress} />
            </>
          )}
          <br />
          <br />
          Tel: {accommodation.phone}
          {accommodation.fax && (
            <>
              <br />
              Fax: {accommodation.fax}
            </>
          )}
          <br />
          <br />
          E-mail: {creatEmailLink(accommodation.email)}
          {!!pecAddress && (
            <>
              <br />
              PEC: {creatEmailLink(pecAddress)}
            </>
          )}
          <br />
          Sito web: {getPrimaryDomain(site)}
        </p>
        <p>
          P.IVA: {site.vatId}
          {checkIsEinzelunternehmen(legalForm) && (
            <>
              <br />
              Codice fiscale: {taxCode}
            </>
          )}
          <br />
          <br />
          {!!reaNumber && (
            <>
              Iscritto nel Registro Imprese della provincia di Bolzano n.
              <br />
              REA n.: {reaNumber}
              <br />
            </>
          )}
          {checkIsLegalFormWithPaidUpShareCapital(legalForm) &&
            paidUpShareCapital && (
              <>
                <br />
                Capitale sociale versato: {formatMoney(paidUpShareCapital)}
              </>
            )}
          {checkIsCompanyWithSoleShareholder(legalForm) && (
            <>
              <br />
              Società con un unico socio
            </>
          )}
        </p>

        <h2>Concetto, layout, programmazione e CMS</h2>
        <p>
          Unione Albergatori e Pubblici Esercenti (HGV)
          <br />
          Via Macello 59
          <br />
          39100 Bolzano
          <br />
          Alto Adige – Italia
          <br />
          Tel. 0471 317 700 – Fax 0471 317 701
          <br />
          E-mail: <a href="mailto:info@hgv.it">info@hgv.it</a>
          <br />
          Sito web:{" "}
          <a target="_blank" href="https://www.hgv.it/">
            www.hgv.it
          </a>
        </p>

        <Copyrights siteId={site.id} languageId={languageId} scheme={scheme} />

        <h2>Diritto d’autore</h2>
        <p>
          I testi, le immagini, le foto e gli altri contenuti riportati su
          questo sito web sono protetti dal diritto d’autore e non possono
          essere riprodotti, salvati, distribuiti e/o trasmessi a terzi,
          completamente o in parte, senza previo consenso.
        </p>
        <p>
          <strong>
            © {accommodation.name} {new Date().getFullYear()} – Tutti i diritti
            riservati
          </strong>
        </p>

        <h2>Trasparenza</h2>
        <p>
          Adempimento degli obblighi di trasparenza e di pubblicità ai sensi
          dell’art. 1, comma 125 ss. legge n. 124 del 4 agosto 2017 e successive
          modifiche:{" "}
          <a
            target="_blank"
            href="https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx"
          >
            https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx
          </a>
        </p>

        <h2>Esclusione di responsabilità</h2>
        <p>
          I contenuti di questo sito web sono stati creati con la massima cura e
          coscienza. Tuttavia, il fornitore di questo sito non si assume alcuna
          responsabilità per la correttezza, completezza e attualità dei
          contenuti forniti. Nonostante un accurato controllo dei contenuti, il
          fornitore non si assume alcuna responsabilità per il contenuto dei
          link esterni riportati sul sito. Il contenuto delle pagine linkate è
          di esclusiva responsabilità dei loro operatori. Qualora si venisse a
          conoscenza di violazioni legali, i link esterni saranno rimossi
          immediatamente.
        </p>

        <p>
          <strong>
            Informazione obbligatoria ai sensi del Regolamento UE n. 524/2013
            del Parlamento europeo e del Consiglio
          </strong>
          <br />
          Piattaforma per la risoluzione delle controversie online dei
          consumatori (ODR) della Commissione europea:{" "}
          <a target="_blank" href="https://ec.europa.eu/consumers/odr/">
            https://ec.europa.eu/consumers/odr/
          </a>
        </p>
      </>
    ),
  },
  en: {
    title: "Credits",
    privacy: "Privacy",
    content: ({
      accommodation,
      site,
      languageId,
      scheme,
      translatedModule: {
        settings: {
          legalForm,
          paidUpShareCapital,
          pecAddress,
          reaNumber,
          taxCode,
        },
        translation: {
          settings: { legalAddress },
        },
      },
    }: TranslationContentParams) => (
      <>
        <p>
          {accommodation.name}{" "}
          {legalForm && <>({legalFormTranslationsIT[legalForm]})</>}
          <br />
          Legal representative: {site.legalRepresentative}
          <br />
          <br />
          {legalAddress ? (
            <>
              Address:
              <br />
            </>
          ) : (
            <>
              Legal address and address:
              <br />
            </>
          )}
          {accommodation.street}
          <br />
          {accommodation.zip} {accommodation.city}
          <br />
          South Tyrol – Italy
          {legalAddress && (
            <>
              <br />
              <br />
              Legal address:
              <br />
              <MultilineText text={legalAddress} />
            </>
          )}
          <br />
          <br />
          Tel: {accommodation.phone}
          {accommodation.fax && (
            <>
              <br />
              Fax: {accommodation.fax}
            </>
          )}
          <br />
          <br />
          E-mail: {creatEmailLink(accommodation.email)}
          {!!pecAddress && (
            <>
              <br />
              PEC: {creatEmailLink(pecAddress)}
            </>
          )}
          <br />
          Website: {getPrimaryDomain(site)}
        </p>
        <p>
          VAT no.: {site.vatId}
          {checkIsEinzelunternehmen(legalForm) && (
            <>
              <br />
              Tax code: {taxCode}
            </>
          )}
          <br />
          <br />
          {!!reaNumber && (
            <>
              Registered in the Register of Companies of the Province of Bolzano
              no.
              <br />
              REA no.: {reaNumber}
              <br />
            </>
          )}
          {checkIsLegalFormWithPaidUpShareCapital(legalForm) &&
            paidUpShareCapital && (
              <>
                <br />
                Paid-up share capital: {formatMoney(paidUpShareCapital)}
              </>
            )}
          {checkIsCompanyWithSoleShareholder(legalForm) && (
            <>
              <br />
              Single-member company
            </>
          )}
        </p>

        <h2>Concept, web design, programming, CMS &amp; Hosting</h2>
        <p>
          Hoteliers- und Gastwirteverband (HGV)
          <br />
          Schlachthofstraße 59
          <br />
          39100 Bozen
          <br />
          South Tyrol – Italy
          <br />
          Tel. 0471 317 700 – Fax 0471 317 701
          <br />
          E-mail: <a href="mailto:info@hgv.it">info@hgv.it</a>
          <br />
          Website:{" "}
          <a target="_blank" href="https://www.hgv.it/">
            www.hgv.it
          </a>
        </p>

        <Copyrights siteId={site.id} languageId={languageId} scheme={scheme} />

        <h2>Copyright</h2>
        <p>
          All texts, images, pictures and other contents on this website are
          protected by copyright and may not be reproduced, saved, trasmitted or
          distributed in any way, in whole or in part, without prior consent.
        </p>
        <p>
          <strong>
            © {accommodation.name} {new Date().getFullYear()} – all rights
            reserved
          </strong>
        </p>

        <h2>Transparency</h2>
        <p>
          Compliance with the obligations of transparency and publicity pursuant
          to art. 1, par. 125 et seq. Law no. 124 of August 4, 2017, and
          subsequent amendments:{" "}
          <a
            target="_blank"
            href="https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx"
          >
            https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/TrasparenzaAiuto.jspx
          </a>
        </p>

        <h2>Limitation of liability</h2>
        <p>
          The contents of this website have been created with the utmost care.
          Nevertheless we cannot guarantee the correctness, completeness and
          topicality of the content provided. Despite careful control we assume
          no liability for the content of external links provided on our
          website. The content of the linked pages is the sole responsibility of
          their operators. With knowledge of rights violations, such external
          links will be deleted immediately.
        </p>

        <p>
          <strong>
            Mandatory information according to the EU-Regulation N. 524/2013 of
            the European Parliament and of the Council:
          </strong>
          <br />
          Platform for online dispute resolution for consumer disputes (ODR) to
          the European Commission:{" "}
          <a target="_blank" href="https://ec.europa.eu/consumers/odr/">
            https://ec.europa.eu/consumers/odr/
          </a>
        </p>
      </>
    ),
  },
};

const ImprintModule: FunctionComponent<Props & StateProps> = ({
  site,
  accommodation,
  i18n,
  scheme,
  translatedModule,
  translatedModule: {
    id,
    translation: { languageId },
  },
}) => {
  return (
    <ModuleWithHeadings
      title={i18n.title}
      subtitle={undefined}
      id={id}
      className="ImprintModule TextModule"
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div
        className={ClassNames(
          "TextModule__Wrapper Module__Wrapper Module__Wrapper--default-padding",
          getLinkStyleClass(scheme)
        )}
      >
        <div className="TextModule__Content TextAlign--left">
          <ModuleHeadings
            title={i18n.title}
            subtitle={undefined}
            scheme={scheme}
            isFirstOnPage={true}
            textAlign={{
              title: "left",
              subtitle: "left",
            }}
          />

          <section className="Module__BodyText TextAlign--left">
            {i18n.content({
              translatedModule,
              site,
              accommodation,
              languageId,
              scheme,
            })}
          </section>
        </div>
      </div>
    </ModuleWithHeadings>
  );
};

const formatMoney = (value: number) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(value);

export const checkIsLegalFormWithPaidUpShareCapital = (
  legalForm: LegalForm | undefined
) => {
  if (!legalForm) return false;

  const companies: LegalForm[] = [
    "Gesellschaft mit beschränkter Haftung",
    "Kommanditgesellschaft auf Aktien",
    "Aktiengesellschaft",
  ];

  return companies.includes(legalForm);
};

const checkIsCompanyWithSoleShareholder = (
  legalForm: LegalForm | undefined
) => {
  if (!legalForm) return false;

  const companies: LegalForm[] = [
    "Ein-Personen-Aktiengesellschaft",
    "Ein-Personen-Gesellschaft",
  ];

  return companies.includes(legalForm);
};

export const checkIsEinzelunternehmen = (legalForm: LegalForm | undefined) =>
  legalForm === "Einzelunternehmen";

const mapStateToProps = (
  { sites, accommodation, colorSchemes }: StoreState,
  {
    translatedModule,
    translatedModule: {
      translation: { languageId },
    },
  }: Props
): StateProps => {
  const site = getActiveSite(sites);
  const accommodationData = accommodation[languageId];
  const i18n = getTranslations(languageId, translations);

  return {
    site,
    accommodation: accommodationData || {},
    i18n,
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
  };
};

export default connect(mapStateToProps)(ImprintModule);
