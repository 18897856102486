import { FunctionComponent, ReactNode, useState } from "react";
import { AllowedLinkTypes, Language, LinkData } from "../types/index.js";
import FormInfo from "./FormInfo.js";
import ModuleTree from "./ModuleTree.js";
import PageTree from "./PageTree.js";

interface Props {
  link?: LinkData | null;
  onLinkChange?: (link: LinkData) => void;
  onSubmit?: (link: LinkData) => void;
  showActionButtons?: boolean;
  showLanguageSelection?: boolean;
  allowedLinkTypes: AllowedLinkTypes;
  languageId?: Language;
  onPageSelect?: (pageId: string) => void;
  children?: ReactNode;
}

export const initialLinkData: LinkData = {
  pageId: null,
  languageId: null,
  moduleId: null,
  moduleType: null,
  url: null,
};

const LinkSelectForm: FunctionComponent<Props> = ({
  link: linkProp,
  allowedLinkTypes,
  showActionButtons = true,
  showLanguageSelection = true,
  languageId,
  children,
  onPageSelect,
  onLinkChange,
  onSubmit,
}) => {
  const [link, setLink] = useState(linkProp ?? initialLinkData);

  const changeLink = (link: Partial<LinkData>) => {
    const newLink = { ...initialLinkData, ...link };
    onLinkChange?.(newLink);
    setLink(newLink);
  };

  const submitLink = (link: LinkData) => {
    onSubmit?.(link);
    setLink(link);
  };

  const actionButtons = showActionButtons && (
    <div className="Form__Field">
      <div className="BtnGroup">
        <button className="Btn" onClick={() => submitLink(link)}>
          Übernehmen
        </button>
        <button
          type="button"
          className="Btn"
          onClick={() => submitLink(initialLinkData)}
        >
          Link entfernen
        </button>
      </div>
    </div>
  );

  return (
    <div className="Form">
      {allowedLinkTypes.external && (
        <div className="Form__Field">
          <input
            placeholder="Link eintippen oder einfügen"
            type="text"
            value={link.url || ""}
            onChange={(e) =>
              changeLink({
                url: e.target.value,
              })
            }
          />
          <FormInfo>
            Auch E-Mail-Adressen und Telefonnummern werden als gültiger Link
            anerkannt!
          </FormInfo>
          <div className="Form__Label">
            <label>Link</label>
          </div>
          {children}
        </div>
      )}
      {actionButtons}
      {allowedLinkTypes.pages && (
        <div className="Form__Field">
          <div className="Form__Content Form__Content--block">
            <PageTree
              languageId={link.languageId || languageId}
              onPageSelect={(pageId, languageId) => {
                onPageSelect?.(pageId);
                changeLink({
                  pageId,
                  languageId,
                });
              }}
              allowFolderSelection={allowedLinkTypes.folders}
              showLanguageSelection={showLanguageSelection}
              languageFallback={false}
              showActionButtons={false}
              activePageId={link.pageId || "no-page"}
            />
          </div>
          <div className="Form__Label">
            <label>Seitenauswahl</label>
          </div>
          {children}
        </div>
      )}
      {allowedLinkTypes.modules && link.pageId && link.languageId && (
        <div className="Form__Field">
          <div className="Form__Content Form__Content--block">
            <ModuleTree
              onModuleSelect={({ id, type }) => {
                changeLink({
                  pageId: link.pageId,
                  languageId: link.languageId,
                  moduleId: id,
                  moduleType: type,
                });
              }}
              activeModuleId={link.moduleId}
              pageId={link.pageId}
              languageId={link.languageId}
            />
          </div>
          <div className="Form__Label">
            <label>Module der aktiven Seite</label>
          </div>
        </div>
      )}
      {actionButtons}
    </div>
  );
};

export default LinkSelectForm;
